import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { NestedFrameSpan } from "../../../model";
import { FunctionWithFrameSpans, getFunctionId } from "../utils/parseTrace";
import ScrollablePane from "./ScrollablePane";
import { toPython } from "../../../extension/toPython";
import clsx from "clsx";
import { OnSelectionState, SelectionState } from "../utils/types";

const CallRow = ({
  frameSpan,
  columns,
  onSelect,
  isSelected,
  onHover,
  isHovered,
  elementRef,
}: {
  frameSpan: NestedFrameSpan;
  columns: ColHeaderProps[];
  onSelect: OnSelectionState;
  isSelected: boolean;
  onHover: OnSelectionState;
  isHovered: boolean;
  elementRef?: React.Ref<HTMLTableRowElement>;
}) => {
  const args = frameSpan.data.call_frame.locals as Record<string, any>;
  const retValue = frameSpan.data.return_frame.arg;
  const selectedState = useMemo(
    () => ({
      functionId: getFunctionId(frameSpan),
      frameIndexes: new Set([frameSpan.index]),
    }),
    [frameSpan]
  );
  const onClick = useCallback(
    () => onSelect(selectedState),
    [onSelect, selectedState]
  );
  const onMouseEnter = useCallback(
    () => onHover(selectedState),
    [onHover, selectedState]
  );
  const onMouseLeave = useCallback(() => onHover({}), [onHover]);
  return (
    <tr
      className={clsx(
        "cursor-pointer",
        isSelected ? "bg-blue-600 text-white" : isHovered ? "bg-gray-100" : ""
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={elementRef}
    >
      {columns.map(({ type, name }) => {
        const value = toPython(type === "argument" ? args[name] : retValue);
        return (
          <td
            key={name}
            className={clsx(
              "max-w-40 px-1 py-0.5 border-b border-gray-200 overflow-hidden text-ellipsis",
              type === "return" ? "bg-black/5" : "border-r",
              { "border-r-blue-500": isSelected }
            )}
            title={value}
          >
            {value}
          </td>
        );
      })}
    </tr>
  );
};

type ColHeaderProps = { type: "argument" | "return"; name: string };

const ColHeader = ({ type, name }: ColHeaderProps) => {
  return (
    <th
      className={clsx(
        "px-1 py-0.5 bg-gray-100/90 border-b border-r border-gray-200 font-normal cursor-default",
        { "text-gray-500 border-gray-300 bg-gray-200/90": type === "return" }
      )}
      title={`${name} (${type})`}
    >
      {type === "return" ? "return" : name}
    </th>
  );
};

export const CallsPane = ({
  functions,
  isPreview,
  selected,
  onSelect,
  hovered,
  onHover,
}: {
  functions: FunctionWithFrameSpans | undefined;
  isPreview: boolean;
  selected: SelectionState;
  onSelect: OnSelectionState;
  hovered: SelectionState;
  onHover: OnSelectionState;
}) => {
  const elementRefs = useRef<Record<string, HTMLTableRowElement>>({});
  // Clear the refs when functions change
  useEffect(() => {
    elementRefs.current = {};
  }, [functions?.frameSpans]);
  useLayoutEffect(() => {
    const frameIndex: number | undefined = Array.from(selected.frameIndexes)[0];
    if (!frameIndex) return;
    const element = elementRefs.current[frameIndex];
    if (!element) return;
    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }, [selected.frameIndexes]);

  const header = (
    <h4>
      Calls{" "}
      {functions?.frameSpans.length ? `(${functions.frameSpans.length})` : ""}
    </h4>
  );
  const argNames = Object.keys(
    functions?.frameSpans?.[0].data.call_frame.locals ?? {}
  );
  const columns: ColHeaderProps[] = functions
    ? [
        ...argNames.map((name) => ({ type: "argument", name }) as const),
        { type: "return", name: "return" } as const,
      ]
    : [];
  return (
    // the `key` ensures the scroll is reset when switching between functions
    <ScrollablePane header={header} key={functions?.name}>
      <table
        className={clsx(
          "w-full font-mono text-xs whitespace-nowrap border-separate",
          { "opacity-50": isPreview }
        )}
        cellPadding={0}
        cellSpacing={0}
      >
        <thead className="sticky top-0 text-left">
          <tr>
            {columns.map((props) => (
              <ColHeader key={props.name} {...props} />
            ))}
          </tr>
        </thead>
        <tbody>
          {functions?.frameSpans.map((frameSpan) => (
            <CallRow
              key={frameSpan.frame_id}
              frameSpan={frameSpan}
              columns={columns}
              onSelect={onSelect}
              isSelected={selected.frameIndexes.has(frameSpan.index)}
              onHover={onHover}
              isHovered={hovered.frameIndexes.has(frameSpan.index)}
              elementRef={(el) =>
                el && (elementRefs.current[frameSpan.frame_id] = el)
              }
            />
          ))}
        </tbody>
      </table>
    </ScrollablePane>
  );
};
