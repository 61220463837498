import type {
  FrameOfInterest,
  ExecutionTreeInfo,
  NonFrameInformationOfInterest,
  EndSQLQuery,
} from "./frames-of-interest-model";
import type {
  ExecutionTreeNode,
  FiveHundredException,
  NestedOutboundRequest,
  NestedBackgroundJobData,
  FrameSpanData,
  NestedSQLQuery,
  NestedBackgroundJob,
  NestedLogMessage,
} from "./model";
import { toPython } from "./extension/toPython";
import { getLogMsgLabel } from "./log";

const callLikeFramesOfInterest = [
  "django_request",
  "start_sql_query",
  "outbound_http_request",
  "start_test",
  "django_template_start",
  "background_job",
  "django_setup_start",
  "django_checks_start",
  "django_create_test_db_start",
];
const returnLikeFramesOfInterest = [
  "django_response",
  "end_sql_query",
  "outbound_http_response",
  "end_test",
  "django_template_end",
  "background_job_end",
  "django_setup_end",
  "django_checks_end",
  "django_create_test_db_end",
];

const leafFramesOfInterest = ["log_message"];

export function makeExecutionTree(
  framesOfInterest: FrameOfInterest[]
): ExecutionTreeInfo {
  if (framesOfInterest.length === 0) {
    return {
      executionTreeNodes: [],
      totalExecutionTreeNodeCount: 0,
      sql_queries: [],
      outbound_http_requests: [],
      background_jobs: [],
      log_messages: [],
    };
  }

  const rootExecutionTreeNodes: ExecutionTreeNode[] = [];

  // Last ancestor in this list is the current parent
  const currentAncestors: ExecutionTreeNode[] = [];

  let executionTreeNodeIndex: number = 0;

  const nonFrameInformationOfInterest = {
    sql_queries: [],
    outbound_http_requests: [],
    background_jobs: [],
    log_messages: [],
  } as NonFrameInformationOfInterest;

  let outbound_http_request_index = 0;
  let sql_query_index = 0;
  let log_message_index = 0;
  let five_hundred_exception: FiveHundredException | undefined = undefined;

  framesOfInterest = preProcessFramesOfInterest(framesOfInterest);

  framesOfInterest.forEach((frameOfInterest, frameOfInterestIndex) => {
    const isReturnFrame =
      "event" in frameOfInterest && frameOfInterest.event === "return";
    const isCallFrame =
      "event" in frameOfInterest && frameOfInterest.event === "call";

    const isCall =
      isCallFrame ||
      (frameOfInterest.type &&
        callLikeFramesOfInterest.includes(frameOfInterest.type));

    const isReturn =
      isReturnFrame ||
      (frameOfInterest.type &&
        returnLikeFramesOfInterest.includes(frameOfInterest.type));

    if (isCall) {
      if (currentAncestors.length === 0) {
        // If there are no currentAncestors, we're entering a new root-level subtree

        const currentTreeNode = makeTreeNode(
          frameOfInterest,
          executionTreeNodeIndex
        );
        currentAncestors.push(currentTreeNode);

        executionTreeNodeIndex += 1;
        return;
      } else {
        // We have a parent, so this new ExecutionTreeNode is a child of this parent

        const currentParentIndex = currentAncestors.length - 1;
        const currentParent = currentAncestors[currentParentIndex];

        const currentTreeNode = makeTreeNode(
          frameOfInterest,
          executionTreeNodeIndex
        );
        currentParent.children.push(currentTreeNode);

        currentAncestors.push(currentTreeNode);

        executionTreeNodeIndex += 1;
        return;
      }
    } else if (isReturn) {
      if (currentAncestors.length === 0) {
        // We find ourselves in a return_frame but with no
        // corresponding call_frame. It's unclear under which
        // circumstances this would currently happen, but we
        // don't support it yet. Currently we just throw away this return frame.
        console.log(
          "Kolo warning: Throwing away return_frame with no corresponding_call frame" +
            toPython(frameOfInterest, true)
        );
        return;

        // I think feasibly we could also have a call statement without a return statement??
        // In that case we "wouldn't have fully completed the subtree" so this is a case
        // where we might currently show 0 frames
      }

      const currentParentIndex = currentAncestors.length - 1;
      const currentParent = currentAncestors[currentParentIndex];

      const expectedParentType = expectedParentTpe(frameOfInterest);
      if (currentParent.type !== expectedParentType) {
        throwMissingReturnFrame(
          currentParent,
          frameOfInterest,
          frameOfInterestIndex,
          framesOfInterest
        );
      } else {
        // We're just silently logging this for now, possibly we'd like to fail hard
        // in case the frame ids don't match in the future.
        const expectedFrameId = currentParent.frame_id;
        const actualFrameId = frameOfInterest.frame_id;
        if (expectedFrameId !== actualFrameId) {
          const debug = frameMisMatchDebugInfo(
            currentParent,
            frameOfInterest,
            framesOfInterest
          );
          console.log(
            `Kolo warning: frame_id mismatch. Expected ${expectedFrameId} (${currentParent.type}), got ${actualFrameId} (${frameOfInterest.type})\n` +
              debug
          );
          console.log(
            debugSurroundingFramesOfInterest(
              frameOfInterestIndex,
              framesOfInterest
            )
          );
        }
      }

      if (isReturnFrame) {
        currentParent.data.return_frame = frameOfInterest;
      } else if (frameOfInterest?.type === "django_response") {
        currentParent.data.response = frameOfInterest;

        if (five_hundred_exception) {
          currentParent.data.five_hundred_exception = five_hundred_exception;

          const { annotatedChildren, exceptionFrameSpanIndex } =
            annotateFrameSpansWith500ExceptionInfo(
              currentParent.children,
              five_hundred_exception
            );
          currentParent.children = annotatedChildren;

          // exceptionFrameSpanIndex will be undefined if the trace has no exception
          // or if we can't match the bottom exception frame to a recorded frame.
          // If we can't match the bottom exception frame to a recorded frame,
          // there will be a warning in the console output.
          currentParent.data.five_hundred_exception_frame_span_index =
            exceptionFrameSpanIndex;

          five_hundred_exception = undefined;
        }

        // Future TODO: What if we don't have a response?
        // Can that ever happen? Should we then skip that?
      } else if (frameOfInterest?.type === "end_sql_query") {
        currentParent.data.index = sql_query_index;
        sql_query_index += 1;

        // Unlike for other types, setting the name here since only the end_sql_query
        // has the query information and can thus set a decent name.
        currentParent.name = frameOfInterestName(frameOfInterest);

        currentParent.data.query = frameOfInterest.query;
        currentParent.data.query_template = frameOfInterest.query_template;
        currentParent.data.query_data = frameOfInterest.query_data;
        currentParent.data.query_params = frameOfInterest.query_params;
        currentParent.data.database = frameOfInterest.database;
        currentParent.data.return_timestamp = frameOfInterest.return_timestamp;
        nonFrameInformationOfInterest.sql_queries.push(
          currentParent as NestedSQLQuery
        );
      } else if (frameOfInterest?.type === "outbound_http_response") {
        currentParent.data.index = outbound_http_request_index;
        outbound_http_request_index += 1;

        currentParent.data.response = {
          body: frameOfInterest.body,
          headers: frameOfInterest.headers,
          timestamp: frameOfInterest.timestamp,
          status_code: frameOfInterest.status_code,
        };
        // Hide a urllib3 outbound http request when it is nested in a requests outbound http request.
        // The urllib3 request is still in the ExecutionTreeNode tree but is hidden from users due to
        // how NestedOutboundRequest is implemented in the visualisation and the sidebar.
        const grandParent = currentAncestors[currentParentIndex - 1] as
          | ExecutionTreeNode
          | undefined;
        if (grandParent?.type !== "outbound_http_request") {
          nonFrameInformationOfInterest.outbound_http_requests.push(
            currentParent as NestedOutboundRequest
          );
        }
      } else if (frameOfInterest?.type === "end_test") {
        currentParent.data.return_timestamp = frameOfInterest.timestamp;
      } else if (frameOfInterest?.type === "django_template_end") {
        currentParent.data.return_timestamp = frameOfInterest.timestamp;
        currentParent.data.return_context = frameOfInterest.context;
      } else if (frameOfInterest?.type === "background_job_end") {
        currentParent.data.return_timestamp = frameOfInterest.timestamp;

        nonFrameInformationOfInterest.background_jobs.push(
          currentParent as NestedBackgroundJob
        );
      } else if (frameOfInterest?.type === "django_setup_end") {
        currentParent.data.return_timestamp = frameOfInterest.timestamp;
      } else if (frameOfInterest?.type === "django_checks_end") {
        currentParent.data.return_timestamp = frameOfInterest.timestamp;
      } else if (frameOfInterest?.type === "django_create_test_db_end") {
        currentParent.data.return_timestamp = frameOfInterest.timestamp;
      } else {
        throw new Error(
          "Unexpected return-like frame of interest" +
            toPython(frameOfInterest, true)
        );
      }

      // We have returned, so successfully combined this subtree
      const currentTreeNode = currentAncestors.pop();
      if (!currentTreeNode) {
        throw new Error("currentTreeNode is unexpectedly falsy");
      }

      if (currentAncestors.length === 0) {
        // We only add to rootExecutionTreeNodes if we've fully completed
        // a root-level subtree
        rootExecutionTreeNodes.push(currentTreeNode);
      }
    } else if (
      frameOfInterest.type &&
      leafFramesOfInterest.includes(frameOfInterest.type)
    ) {
      // Leaf nodes fall out of the usual call/return model so we handle them separately here.

      const currentParentIndex = currentAncestors.length - 1;
      const currentParent = currentAncestors[currentParentIndex];

      const currentTreeNode = makeTreeNode(
        frameOfInterest,
        executionTreeNodeIndex
      );

      if (currentTreeNode.type === "log_message") {
        currentTreeNode.data.index = log_message_index;
        log_message_index += 1;

        nonFrameInformationOfInterest.log_messages.push(
          currentTreeNode as NestedLogMessage
        );
      }

      const currentParentExists = currentParentIndex >= 0;
      if (currentParentExists) {
        // Often, we have a current parent, that's what this branch handles:
        // We attach the complete node as a child to the current parent and won't modify it again later.
        currentParent.children.push(currentTreeNode);
      } else {
        // But it's possible that the first thing that happens when a program
        // starts up is that it logs a message.
        // In that case the single leaf node would be the first complete root-level
        // subtree, which we handle here.
        rootExecutionTreeNodes.push(currentTreeNode);
      }

      executionTreeNodeIndex += 1;
      return;
    } else if (frameOfInterest.type === "exception") {
      // A 500 exception or "exception" in frames_of_interest can occur once per request
      // and describes the specific case where an exception caused django to serve a 500
      // "exception" appears as a single entry in frames_of_interest (at most once per request)
      // And is handled as a special case, as opposed to as a leaf node.

      five_hundred_exception = frameOfInterest;
    } else {
      // @ts-ignore
      if (!(frameOfInterest.type === "sql_query_data")) {
        console.debug(
          `Unexpected frames_of_interest type: ${
            frameOfInterest.type
          }, ${toPython(frameOfInterest, true)}`
        );
      }
    }
  });

  if (currentAncestors.length !== 0) {
    // We have not completed some subtrees. Any frames in these incomplete subtrees
    // are not propagated to the rootExecutionTreeNodes and as such not surfaced
    // to the user.

    console.log("Kolo Warning: incomplete subtrees exist");
    currentAncestors.forEach((ancestor, index) => {
      console.log(index, ancestor.children.length, ancestor.name);
    });
    // In an ideal world we could surface this to the user directly in the sidebar
  }

  const executionTreeNodes = postProcess(rootExecutionTreeNodes);
  setChildrenCounts(executionTreeNodes);

  return {
    executionTreeNodes,
    totalExecutionTreeNodeCount:
      totalExecutionTreeNodeCount(executionTreeNodes),
    ...nonFrameInformationOfInterest,
  };
}

export function getNumberOfLines(str: string) {
  const lines = str.length - str.replace(/\n/g, "").length;

  return lines;
}

export function getExceptionMessageFromException(
  exception: FiveHundredException
): string {
  const { exception_summary } = exception;
  return exception_summary[exception_summary.length - 1];
}

function frameOfInterestName(frameOfInterest: FrameOfInterest): string {
  // Used for "{name} and 123 other calls" display in the sidebar/webview label and in compact tree

  const unrenderedTypes = [
    "start_sql_query", // we use "end_sql_query" instead
    "django_setup_start",
    "django_checks_start",
    "django_create_test_db_start",
  ];

  if (frameOfInterest.type === undefined || frameOfInterest.type === "frame") {
    return (
      frameOfInterest.qualname ??
      `${frameOfInterest.path} ${frameOfInterest.co_name}`
    );
  } else if (
    frameOfInterest.type === "start_test" ||
    frameOfInterest.type === "end_test"
  ) {
    return `${frameOfInterest.test_name}`;
  } else if (
    frameOfInterest.type === "django_template_start" ||
    frameOfInterest.type === "django_template_end"
  ) {
    return `${frameOfInterest.template}`;
  } else if (frameOfInterest.type === "django_request") {
    return `${frameOfInterest.method} ${frameOfInterest.path_info}`;
  } else if (frameOfInterest.type === "log_message") {
    return getLogMsgLabel(frameOfInterest);
  } else if (frameOfInterest.type === "end_sql_query") {
    return `${frameOfInterest.query} (${frameOfInterest.frame_id})`;
  } else if (frameOfInterest.type === "outbound_http_request") {
    return `${frameOfInterest.method_and_full_url}`;
  } else if (
    frameOfInterest.type === "background_job" ||
    frameOfInterest.type === "background_job_end"
  ) {
    return `${frameOfInterest.name}`;
  } else if (unrenderedTypes.includes(frameOfInterest.type)) {
    return `${frameOfInterest.type}`;
  } else {
    console.log(
      `Consider specifying a better frameOfInterestName for: ${frameOfInterest.type}`
    );
    return `${frameOfInterest.type}`;
  }
}

function calculateAllChildrenCount(treeNode: ExecutionTreeNode): number {
  const shallowSum = treeNode.children.reduce(
    (sum: number, node: ExecutionTreeNode) => sum + node.all_children_count,
    0
  );

  return treeNode.children.length + shallowSum;
}

export function totalExecutionTreeNodeCount(
  treeNodes: ExecutionTreeNode[]
): number {
  return treeNodes.reduce(
    (sum, treeNode) => sum + (1 + treeNode.all_children_count),
    0
  );
}

function makeTreeNode(
  frameOfInterest: FrameOfInterest,
  executionTreeNodeIndex: number
): ExecutionTreeNode {
  const base = {
    index: executionTreeNodeIndex,
    name: frameOfInterestName(frameOfInterest),
    children: [],
    all_children_count: 0,
    frame_id: frameOfInterest.frame_id,
  };
  if ("event" in frameOfInterest && frameOfInterest.event === "call") {
    return {
      ...base,
      type: "frame_span",
      data: {
        call_frame: frameOfInterest,
      },
    };
  } else if (frameOfInterest?.type === "django_request") {
    return {
      ...base,
      type: "nested_served_http_request",
      data: {
        request: frameOfInterest,
      },
    };
  } else if (frameOfInterest?.type === "start_sql_query") {
    return {
      ...base,
      type: "sql_query",
      data: {
        user_code_call_site: frameOfInterest.user_code_call_site,
        call_timestamp: frameOfInterest.call_timestamp,
      },
    };
  } else if (frameOfInterest?.type === "outbound_http_request") {
    return {
      ...base,
      type: "outbound_http_request",
      subtype: frameOfInterest.subtype,
      data: {
        request: {
          url: frameOfInterest.url,
          body: frameOfInterest.body,
          method: frameOfInterest.method,
          headers: frameOfInterest.headers,
          timestamp: frameOfInterest.timestamp,
          method_and_full_url: frameOfInterest.method_and_full_url,
        },
      },
    };
  } else if (frameOfInterest?.type === "start_test") {
    return {
      ...base,
      type: "nested_test",
      data: {
        test_name: frameOfInterest.test_name,
        test_class: frameOfInterest.test_class,
        call_timestamp: frameOfInterest.timestamp,
      },
    };
  } else if (frameOfInterest?.type === "django_template_start") {
    return {
      ...base,
      type: "django_template",
      data: {
        call_timestamp: frameOfInterest.timestamp,
        template: frameOfInterest.template,
        call_context: frameOfInterest.context,
      },
    };
  } else if (frameOfInterest?.type === "background_job") {
    const data: Omit<NestedBackgroundJobData, "return_timestamp"> = {
      call_timestamp: frameOfInterest.timestamp,
      name: frameOfInterest.name,
      args: frameOfInterest.args,
      kwargs: frameOfInterest.kwargs,
      subtype: frameOfInterest.subtype,
    };

    return {
      ...base,
      type: "nested_background_job",
      data,
    };
  } else if (frameOfInterest?.type === "log_message") {
    return {
      ...base,
      type: "log_message",
      data: {
        level: frameOfInterest.level,
        args: frameOfInterest.args,
        extra: frameOfInterest.extra,
        msg: frameOfInterest.msg,
        stack: frameOfInterest.stack,
        traceback: frameOfInterest.traceback,
        name: frameOfInterest?.name,
      },
    };
  } else if (frameOfInterest?.type === "django_setup_start") {
    return {
      ...base,
      type: "django_setup",
      data: {},
    };
  } else if (frameOfInterest?.type === "django_checks_start") {
    return {
      ...base,
      type: "django_checks",
      data: {},
    };
  } else if (frameOfInterest?.type === "django_create_test_db_start") {
    return {
      ...base,
      type: "django_create_test_db",
      data: {},
    };
  } else {
    throw new Error(
      "Unexpected frameOfInterest: " + toPython(frameOfInterest, true)
    );
  }
}

function expectedParentTpe(frameOfInterest: FrameOfInterest): string {
  if ("event" in frameOfInterest && frameOfInterest.event === "return") {
    return "frame_span";
  } else if ("event" in frameOfInterest && frameOfInterest.event === "call") {
    throw new Error(
      "Unexpected parent check for call frameOfInterest" +
        toPython(frameOfInterest, true)
    );
  }

  if (callLikeFramesOfInterest.includes(frameOfInterest.type)) {
    throw new Error(
      "Unexpected parent check for call-like frameOfInterest" +
        toPython(frameOfInterest, true)
    );
  }
  if (leafFramesOfInterest.includes(frameOfInterest.type)) {
    throw new Error(
      "Unexpected parent check for leaf frameOfInterest" +
        toPython(frameOfInterest, true)
    );
  }

  const expectedParentTypes = {
    django_response: "nested_served_http_request",
    end_sql_query: "sql_query",
    outbound_http_response: "outbound_http_request",
    end_test: "nested_test",
    django_template_end: "django_template",
    background_job_end: "nested_background_job",
    django_setup_end: "django_setup",
    django_checks_end: "django_checks",
    django_create_test_db_end: "django_create_test_db",
  };

  if (frameOfInterest.type in expectedParentTypes) {
    // @ts-ignore
    return expectedParentTypes[frameOfInterest.type];
  } else {
    throw new Error(
      "Unexpected frameOfInterest when determining parent type: " +
        toPython(frameOfInterest, true)
    );
  }
}

function annotateFrameSpansWith500ExceptionInfo(
  executionTreeNodes: ExecutionTreeNode[],
  exception: FiveHundredException
): {
  annotatedChildren: ExecutionTreeNode[];
  exceptionFrameSpanIndex: number | undefined;
} {
  for (const [index, exceptionFrame] of exception.exception_frames.entries()) {
    const isBottomExceptionFrame =
      index === exception.exception_frames.length - 1;

    let children = executionTreeNodes;
    for (const node of children) {
      if (node.type !== "frame_span") {
        continue;
      }
      const { return_frame } = node.data as FrameSpanData;
      if (
        return_frame.path === exceptionFrame.path &&
        return_frame.co_name === exceptionFrame.co_name
      ) {
        if (isBottomExceptionFrame) {
          node.data.return_frame.exception = exception;
          return {
            annotatedChildren: executionTreeNodes,
            exceptionFrameSpanIndex: node.index,
          };
        } else {
          return_frame.exceptionMessage =
            getExceptionMessageFromException(exception);
        }

        // Enter the next for (const node of children) loop with a new value for children
        children = node.children;
        break;
      }
    }

    // This happens so often and the exception matching is so fragile that I'm going to
    // disable this for now.
    // console.log(
    //   "Warning: Could not find frame matching exception frame" +
    //     toPython(exceptionFrame, true)
    // );
  }

  // If we can't find the frame we expect, then we bail and return the executionTreeNodes unmodified
  return {
    annotatedChildren: executionTreeNodes,
    exceptionFrameSpanIndex: undefined,
  };
}

function removeLegacyUrllibFrames() {
  return (framesOfInterest: FrameOfInterest[]): FrameOfInterest[] => {
    return framesOfInterest.filter((frame) => {
      // We need to filter out these urlopen frames because otherwise
      // outbound_http_request and outbound_http_response are not next to each other
      if (
        "qualname" in frame &&
        frame.qualname === "urllib3.connectionpool.HTTPConnectionPool.urlopen"
      ) {
        return false;
      } else {
        return true;
      }
    });
  };
}

function removeLegacyBackgroundJobFrames() {
  return (framesOfInterest: FrameOfInterest[]): FrameOfInterest[] => {
    let backgroundJobCount = 0;
    let backgroundJobEndCount = 0;
    framesOfInterest.forEach((frame) => {
      if (frame.type === "background_job") {
        backgroundJobCount++;
      } else if (frame.type === "background_job_end") {
        backgroundJobEndCount++;
      }
    });

    // We need to filter out these legacy background_job frames so that they
    // don't overlap with the nested background jobs
    if (backgroundJobCount !== 0 && backgroundJobEndCount === 0) {
      return framesOfInterest.filter(
        (frame) => frame.type !== "background_job"
      );
    } else {
      return framesOfInterest.filter((frame) => {
        if (
          "qualname" in frame &&
          frame.qualname === "celery.app.task.Task.apply_async"
        ) {
          return false;
        } else {
          return true;
        }
      });
    }
  };
}

function removeEmptySqlQueries() {
  return (framesOfInterest: FrameOfInterest[]): FrameOfInterest[] => {
    return framesOfInterest.filter((frame, index, array) => {
      const type = frame.type;
      if (!["start_sql_query", "end_sql_query"].includes(String(type))) {
        return true;
      }

      if (frame.type === "start_sql_query") {
        let nextItem = array[index + 1];
        if (nextItem.type !== "end_sql_query") {
          // Sometimes a log message can sneak in between the start and end sql query
          // which this branch accounts for. Ideally we'd operate on a tree here, but this simpler method will work for now.
          nextItem = array[index + 2] as EndSQLQuery;
        }
        if (nextItem.type !== "end_sql_query") {
          console.log(
            "Error: Expected end_sql_query after start_sql_query but got:",
            nextItem
          );
        }
        if (nextItem.query === null) {
          // If the end_sql_query query is null, then we want to filter out both.
          // Including the current item (start_sql_query)
          return false;
        }
      } else if (frame.type === "end_sql_query") {
        if (frame.query === null) {
          return false;
        }
      }
      return true;
    });
  };
}

type FrameTransform = (
  framesOfInterest: FrameOfInterest[]
) => FrameOfInterest[];

const preProcessFramesOfInterest: FrameTransform = pipe(
  removeLegacyUrllibFrames(),
  removeLegacyBackgroundJobFrames(),
  removeEmptySqlQueries()
);

function pipe(...fns: Function[]) {
  // Boilerplate functional pipe so that we can compose functions
  // and do some nice chaining.
  return (input: any) => fns.reduce((acc, fn) => fn(acc), input);
}

function setChildrenCounts(nodes: ExecutionTreeNode[]): void {
  nodes.forEach((node) => {
    node.children.forEach((child) => {
      setChildrenCounts([child]);
    });
    node.all_children_count = calculateAllChildrenCount(node);
  });
}

function* dfsWithParent(
  treeNodes: ExecutionTreeNode[],
  parent: ExecutionTreeNode | null = null
): Generator<[ExecutionTreeNode, ExecutionTreeNode | null]> {
  for (const node of treeNodes) {
    yield [node, parent];
    yield* dfsWithParent(node.children, node);
  }
}

function postProcess(nodes: ExecutionTreeNode[]): ExecutionTreeNode[] {
  // Remove entire subtrees by updating the node's parent's children to no longer include the node.
  // Currently only removes django_setup subtrees

  const removeChildrenOfTheseNodes = [
    "django_setup",
    "django_checks",
    "django_create_test_db",
  ];

  const dfsGenerator = dfsWithParent(nodes);
  for (const [node, parent] of dfsGenerator) {
    if (removeChildrenOfTheseNodes.includes(node.type)) {
      if (parent) {
        parent.children = parent.children.filter((child) => child !== node);
      } else {
        nodes = nodes.filter((root) => root !== node);
      }
    }
  }

  removeSuperfluousUrllib3Frames(nodes);

  return nodes;
}

function removeSuperfluousUrllib3Frames(nodes: ExecutionTreeNode[]): void {
  // urllib3 frames include nothing extra over requests frames.
  // Removing them means we won't show one outbound HTTP request nested within another
  for (const node of nodes) {
    if (node.type === "outbound_http_request" && node.subtype === "requests") {
      node.children = node.children.flatMap((child) => {
        if (
          child.type === "outbound_http_request" &&
          child.subtype === "urllib3"
        ) {
          // Move urllib3 children up to the requests node
          return child.children;
        }
        return [child];
      });
    }
    // Recursively process children
    removeSuperfluousUrllib3Frames(node.children);
  }
}

function frameMisMatchDebugInfo(
  currentParent: ExecutionTreeNode,
  frameOfInterest: FrameOfInterest,
  allFramesOfInterest: FrameOfInterest[]
): string {
  const currentParentFrameId = currentParent.frame_id;
  const currentReturnFrameOfInterestFrameId = frameOfInterest.frame_id;

  const frameMisMatchDebugOutput = [];
  let intermediateFrameCount = 0;
  let indentationStack = [];

  for (let frame of allFramesOfInterest) {
    let callOrReturn;

    if (!frame.type || frame.type === "frame") {
      callOrReturn = frame.event;
    } else {
      if (callLikeFramesOfInterest.includes(frame.type)) {
        callOrReturn = "call";
      } else if (returnLikeFramesOfInterest.includes(frame.type)) {
        callOrReturn = "return";
      } else if (leafFramesOfInterest.includes(frame.type)) {
        callOrReturn = "leaf";
      } else {
        callOrReturn = "unknown_call_or_return";
      }
    }

    let indent = "  ".repeat(indentationStack.length);

    if (
      frame.frame_id === currentParentFrameId ||
      frame.frame_id === currentReturnFrameOfInterestFrameId
    ) {
      if (intermediateFrameCount > 0) {
        frameMisMatchDebugOutput.push(
          `${indent}(${intermediateFrameCount} frames)`
        );
        intermediateFrameCount = 0;
      }

      if (callOrReturn === "call") {
        frameMisMatchDebugOutput.push(
          `${indent}${callOrReturn} (${frame.type}) {${frame.frame_id}}`
        );
        indentationStack.push(callOrReturn);
      } else if (callOrReturn === "return") {
        if (indentationStack.length > 0) {
          indentationStack.pop();
        }
        indent = "  ".repeat(indentationStack.length); // Recalculate indent
        frameMisMatchDebugOutput.push(
          `${indent}${callOrReturn} (${frame.type}) {${frame.frame_id}}`
        );
      }
    } else {
      intermediateFrameCount++;
    }
  }

  if (intermediateFrameCount > 0) {
    const indent = "  ".repeat(indentationStack.length);
    frameMisMatchDebugOutput.push(
      `${indent}(${intermediateFrameCount} frames)`
    );
  }

  return frameMisMatchDebugOutput.join("\n");
}

function strippedFrameOfInterest(frameOfInterest: FrameOfInterest): string {
  const allowedKeys = [
    "frame_id",
    "type",
    "subtype",
    "event",
    "qualname",
    "path",
    "co_name",
  ];
  return JSON.stringify(frameOfInterest, allowedKeys);
}

function debugSurroundingFramesOfInterest(
  index: number,
  framesOfInterest: FrameOfInterest[]
) {
  const range = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];

  let output = "flat surrounding frames of interest:\n";
  range.forEach((offset) => {
    const currentIndex = index + offset;
    if (currentIndex >= 0 && currentIndex < framesOfInterest.length) {
      output += `${offset} ${strippedFrameOfInterest(framesOfInterest[currentIndex])}\n`;
    } else {
      output += `${offset} [out of bounds]\n`;
    }
  });

  return output;
}

function throwMissingReturnFrame(
  currentParent: ExecutionTreeNode,
  frameOfInterest: FrameOfInterest,
  frameOfInterestIndex: number,
  allFramesOfInterest: FrameOfInterest[]
) {
  // Called when we can't close a any execution tree node pair (frame or otherwise)

  const debugOutput = frameMisMatchDebugInfo(
    currentParent,
    frameOfInterest,
    allFramesOfInterest
  );

  throw new Error(
    `Could not build tree\n` +
      `Frame with no corresponding return: ${currentParent.frame_id} (${currentParent.name})\n\n` +
      debugOutput +
      "\n\n" +
      drawTree(currentParent) +
      "\n\n" +
      debugSurroundingFramesOfInterest(
        frameOfInterestIndex,
        allFramesOfInterest
      )
  );
}
// Debugging utility for drawing an entire execution tree from any node
// using ASCII art and printing it in the console
// @ts-ignore
function drawTree(node: ExecutionTreeNode, prefix = ""): string {
  let output = `${prefix}${node.name}\n`;
  const n = node.children.length;
  node.children.forEach((child, index) => {
    const newPrefix = index === n - 1 ? `${prefix}    ` : `${prefix}│   `;
    output += drawTree(child, newPrefix);
  });
  return output;
}
