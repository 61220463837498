// NOTE: we're using a patched version of msgpack-es until a patch gets merged:
// https://github.com/samclaus/msgpack-es/pull/2
import { decode, registerExtension, DECODE_OPTS } from "@gavrilov/msgpack-es";

import type { Invocation } from "../model";
import type { FrameOfInterest } from "../frames-of-interest-model";
import {
  BrokenRepr,
  PythonDate,
  PythonDateTime,
  PythonFrozenSet,
  PythonObject,
  PythonSet,
  PythonTime,
  PythonTuple,
} from "./toPython";

DECODE_OPTS.forceES6Map = true;
DECODE_OPTS.nilValue = null;
DECODE_OPTS.unknownExtHandler = (id) => {
  throw new Error(`Unexpected msgpack object type with id ${id}`);
};

// Keep decoders in an array to make it easier to swap out msgpack libraries
const decoders: [number, (buffer: Uint8Array) => any][] = [
  // Python repr extension
  [
    0,
    (buffer) => {
      return new PythonObject(new TextDecoder().decode(buffer));
    },
  ],
  // DateTime extension
  [
    1,
    (buffer) => {
      return new PythonDateTime(new TextDecoder().decode(buffer));
    },
  ],
  // Date extension
  [
    2,
    (buffer) => {
      return new PythonDate(new TextDecoder().decode(buffer));
    },
  ],
  // Time extension
  [
    3,
    (buffer) => {
      return new PythonTime(new TextDecoder().decode(buffer));
    },
  ],
  // Integer extension
  [
    4,
    (buffer) => {
      return bufToBigInt(buffer) as any;
    },
  ],
  // Decimal extension
  [
    5,
    (buffer) => {
      return Number(new TextDecoder().decode(buffer)) as any;
    },
  ],
  // Tuple extension
  [
    6,
    (buffer) => {
      return new PythonTuple(decode(buffer) as any[]);
    },
  ],
  // Set extension
  [
    7,
    (buffer) => {
      return new PythonSet(decode(buffer) as any[]);
    },
  ],
  // Frozenset extension
  [
    8,
    (buffer) => {
      return new PythonFrozenSet(decode(buffer) as any[]);
    },
  ],

  [
    127,
    (buffer) => {
      return new BrokenRepr(new TextDecoder().decode(buffer));
    },
  ],
];

// Register all the extensions
for (const [id, decode] of decoders) {
  registerExtension(
    id,
    // We never encode msgpack on the frontend, so leaving the encoders blank
    undefined as any,
    undefined as any,
    decode
  );
}

// https://coolaj86.com/articles/convert-hex-to-decimal-with-js-bigints/
// https://coolaj86.com/articles/convert-js-bigints-to-typedarrays/
function bufToBigInt(buffer: Uint8Array) {
  let hex_array: string[] = [];
  buffer.forEach(function (i) {
    let h = i.toString(16);
    if (h.length % 2) {
      h = "0" + h;
    }
    hex_array.push(h);
  });

  let hex = hex_array.join("");

  if (hex.length % 2) {
    hex = "0" + hex;
  }

  var highbyte = parseInt(hex.slice(0, 2), 16);
  var bn = BigInt("0x" + hex);

  if (0x80 & highbyte) {
    // bn = ~bn; WRONG in JS (would work in other languages)

    // manually perform two's compliment (flip bits, add one)
    // (because JS binary operators are incorrect for negatives)
    bn =
      BigInt(
        "0b" +
          bn
            .toString(2)
            .split("")
            .map(function (i: any) {
              return "0" === i ? 1 : 0;
            })
            .join("")
      ) + BigInt(1);
    // add the sign character to output string (bytes are unaffected)
    bn = -bn;
  }

  return bn;
}

export function msgpackLoad(buffer: Uint8Array): Map<any, any> {
  return decode(buffer);
}

function toObject(
  parent: { [key: string]: any },
  type: string,
  attribute: string
) {
  if (
    parent.type === type &&
    attribute in parent &&
    parent[attribute] !== null
  ) {
    parent[attribute] = Object.fromEntries(parent[attribute]);
  }
}

function toFrame(frame: Map<any, any>): FrameOfInterest {
  let frame_object = Object.fromEntries(frame);
  toObject(frame_object, "background_job", "kwargs");
  toObject(frame_object, "django_request", "headers");
  toObject(frame_object, "django_request", "url_pattern");
  toObject(frame_object, "django_response", "headers");
  toObject(frame_object, "django_response", "url_pattern");
  toObject(frame_object, "django_template_start", "context");
  toObject(frame_object, "django_template_end", "context");
  toObject(frame_object, "frame", "exception");
  toObject(frame_object, "frame", "expanded_locals");
  toObject(frame_object, "frame", "locals");
  toObject(frame_object, "frame", "user_code_call_site");
  toObject(frame_object, "outbound_http_request", "headers");
  toObject(frame_object, "outbound_http_response", "headers");
  toObject(frame_object, "start_sql_query", "user_code_call_site");
  return frame_object;
}

export function toInvocation(trace_map: Map<any, any>): Invocation {
  let trace = Object.fromEntries(trace_map);
  trace.frames_of_interest = trace.frames_of_interest.map(toFrame);

  let frames = trace.frames;
  trace.frames = {};
  for (const [key, frame_list] of frames.entries()) {
    trace.frames[key] = frame_list.map(toFrame);
  }

  trace.meta = Object.fromEntries(trace.meta);
  return trace;
}
